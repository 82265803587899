import { Toaster as IToaster } from 'rsuite'

class Toaster {
  toaster: IToaster

  constructor(toaster: IToaster) {
    this.toaster = toaster
  }
}

export default Toaster
