import { FC, MutableRefObject, useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Button, ButtonToolbar } from 'rsuite'

import useSupplierProfileData from '../hooks/useSupplierProfileData'
import SupplierShippingDetails from '../Manage/Profile/SupplierShippingDetails'
import ProfileContentWrapper from '../ProfileContentWrapper'
import { StepActions } from '../../utils/types/misc'

import styles from '../../styles/Onboarding.module.less'

interface ShippingDetailsOnboardingProps extends StepActions {
  isFirstMount: MutableRefObject<boolean>
}

const ShippingDetailsOnboarding: FC<ShippingDetailsOnboardingProps> = (props) => {
  const { nextAction, isFirstMount } = props

  const { t } = useTranslation('profile')
  const {
    isDirty,
    setDirty,
    isSupplierInfoLoading,
    isDeliveryServiceLoading,
    deliveryData,
  } = useSupplierProfileData()

  const isDeliveryDataAvailable = !isDeliveryServiceLoading && Object.keys(deliveryData?.['service.config'] || []).length

  // Skip supplier shipping details if the user delivery data has already been set
  // Also check on first mount only, because after making an update to the delivery data
  // this effect will rerender and auto-skip, so this will prevent it
  useEffect(() => {
    if (isFirstMount.current && isDeliveryDataAvailable) nextAction()
    isFirstMount.current = false
  }, [])

  const controlButtons = (
    <ButtonToolbar className="margin-top-form-group">
      <Button
        data-testid="next-button"
        appearance="primary"
        disabled={isSupplierInfoLoading || isDeliveryServiceLoading || !isDeliveryDataAvailable}
        onClick={nextAction}
      >
        Next
      </Button>
    </ButtonToolbar>
  )

  return (
    <ProfileContentWrapper
      isDirty={isDirty}
      changeIndicatorText={t('All changes are automatically saved')}
      mainHeader={t('Supplier shipping details')}
      subHeader={t('checkout:Delivery terms')}
      controlButtonComponent={controlButtons}
      additionalContentClassName={styles['onboarding-profile-content-wrapper']}
      schemaModel={{}} // No schema required
    >
      <SupplierShippingDetails setDirty={setDirty} />
    </ProfileContentWrapper>
  )
}

export default ShippingDetailsOnboarding
