import { FC, ReactNode } from 'react'
import { Tooltip, Whisper } from 'rsuite'

import LightningIcon, { LightningIconSize } from './Icons/LightningIcon'
import { Placement } from '../utils/types/rsuite'
import useIsOnMobile from '../services/useIsOnMobile'
import { ScreenSize } from '../utils/constants'

interface LightningTooltipProps {
  content: ReactNode
  placement?: Placement
  className?: string
  size?: LightningIconSize
}

const LightningTooltip: FC<LightningTooltipProps> = (props) => {
  const { content, size = 'xs', placement = 'top', className } = props
  const isOnMobile = useIsOnMobile(ScreenSize.md)

  return (
    <Whisper
      placement={isOnMobile ? 'auto' : placement}
      trigger="hover"
      speaker={(
        <Tooltip>
          {content}
        </Tooltip>
      )}
    >
      <span className={className}>
        <LightningIcon size={size} />
      </span>
    </Whisper>
  )
}

export default LightningTooltip
