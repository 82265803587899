import { FC, SyntheticEvent } from 'react'

import BaseModal from './BaseModal'

interface ConfirmModalProps {
  title: string
  content: React.ReactNode
  confirmButtonText: string
  confirm: (event: SyntheticEvent) => void
  cancel: (event: SyntheticEvent) => void
  open: boolean
  closeButtonText?: string
  positive?: boolean
  shouldDisplayCloseIcon?: boolean
  backdrop?: boolean | 'static'
  modalClassName?: string
}

const ConfirmModal: FC<ConfirmModalProps> = (props) => {
  const {
    title,
    content,
    confirmButtonText,
    closeButtonText = 'Cancel',
    confirm,
    cancel,
    open,
    backdrop = true,
    shouldDisplayCloseIcon = true,
    modalClassName = '',
  } = props

  return (
    <BaseModal
      isOpen={open}
      closeModal={cancel}
      title={title}
      size="sm"
      modalAction={confirm}
      actionButtonText={confirmButtonText}
      closeButtonText={closeButtonText}
      backdrop={backdrop}
      modalClassName={modalClassName}
      shouldDisplayCloseIcon={shouldDisplayCloseIcon}
    >
      {content}
    </BaseModal>
  )
}

export default ConfirmModal
