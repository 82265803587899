import { FC, useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Button, ButtonToolbar } from 'rsuite'
import { captureException } from '@sentry/nextjs'

import useSupplierProfileData from '../hooks/useSupplierProfileData'
import SupplierProfile from '../Manage/Profile/SupplierProfile'
import ProfileContentWrapper from '../ProfileContentWrapper'
import { StepActions } from '../../utils/types/misc'

import styles from '../../styles/Onboarding.module.less'

const SupplierProfileOnboarding: FC<StepActions> = (props) => {
  const { nextAction } = props

  const { t } = useTranslation('profile')
  const {
    supplierInfo,
    fetchedSupId,
    fileInfo,
    handleFormInputChange,
    isDirty,
    isSaving,
    isSupplierInfoLoading,
    isUploadingImage,
    setDirty,
    setFileInfo,
    setImageData,
    setIsSaving,
    supplierData,
    updateSupplierProfile,
    uploaderRef,
  } = useSupplierProfileData()

  const { supplierProfileData } = supplierData
  const supplierInfoTextArr = supplierInfo?.[0]?.text
  const isSupplierDataAvailable = !isSupplierInfoLoading
    && (supplierInfoTextArr?.length && supplierInfoTextArr?.[0]?.['text.content'])

  // Skip supplier profile step if the company story is available
  useEffect(() => {
    if (isSupplierDataAvailable) nextAction()
  }, [
    isSupplierInfoLoading,
    supplierInfoTextArr?.length,
  ])

  const onNextClick = async () => {
    setIsSaving(true)
    try {
      await updateSupplierProfile()
      nextAction()
    } catch (error) {
      captureException(error)
      console.error(error)
    } finally {
      setIsSaving(false)
    }
  }

  const isSupplierDataNotComplete = !supplierProfileData['supplier.label'] || !supplierProfileData.text?.[0]?.['text.content']

  const controlButtons = (
    <ButtonToolbar className="margin-top-form-group">
      <Button
        data-testid="submit-button"
        appearance="primary"
        onClick={onNextClick}
        disabled={isSupplierInfoLoading || isSupplierDataNotComplete}
        loading={isSaving}
      >
        Submit
      </Button>
    </ButtonToolbar>
  )

  return (
    <ProfileContentWrapper
      controlButtonComponent={controlButtons}
      isDirty={isDirty}
      changeIndicatorText={t('Unsaved changes')}
      mainHeader={t('Supplier profile')}
      subHeader={t('Supplier\'s name, country and story')}
      additionalContentClassName={styles['onboarding-profile-content-wrapper']}
      schemaModel={{}}
    >
      <SupplierProfile
        handleFormInputChange={handleFormInputChange}
        supplierProfilesData={supplierData.supplierProfileData}
        supplierInfo={supplierInfo}
        fetchedSupId={fetchedSupId}
        isSupplierInfoLoading={isSupplierInfoLoading}
        fileInfo={fileInfo}
        uploaderRef={uploaderRef}
        setImageData={setImageData}
        setFileInfo={setFileInfo}
        setDirty={setDirty}
        isUploadingImage={isUploadingImage}
        fromOnboardingFlow
      />
    </ProfileContentWrapper>
  )
}

export default SupplierProfileOnboarding
