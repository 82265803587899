import { selectCartAddressEmail } from '../../../components/Cart/selectors'
import { CartContextType } from '../../../utils/types/Cart'

class Cart {
  cartState: CartContextType['cartState']

  modifiedCart: CartContextType['modifiedCart']

  isMovModalVisible: CartContextType['isMovModalVisible']

  toggleMovModal: CartContextType['toggleMovModal']

  refresh: CartContextType['refresh']

  addProduct: CartContextType['addProduct']

  updateProduct: CartContextType['updateProduct']

  deleteProduct: CartContextType['deleteProduct']

  addPayment: CartContextType['addPayment']

  addSingleAddress: CartContextType['addSingleAddress']

  addAddress: CartContextType['addAddress']

  updateSingleAddress: CartContextType['updateSingleAddress']

  updateAddress: CartContextType['updateAddress']

  deleteAddresses: CartContextType['deleteAddresses']

  updateCart: CartContextType['updateCart']

  clearCart: CartContextType['clearCart']

  createOrder: CartContextType['createOrder']

  constructor(model: CartContextType) {
    this.cartState = model.cartState
    this.modifiedCart = model.modifiedCart
    this.isMovModalVisible = model.isMovModalVisible
    this.toggleMovModal = model.toggleMovModal
    this.refresh = model.refresh
    this.addProduct = model.addProduct
    this.updateProduct = model.updateProduct
    this.deleteProduct = model.deleteProduct
    this.addPayment = model.addPayment
    this.addSingleAddress = model.addSingleAddress
    this.addAddress = model.addAddress
    this.updateSingleAddress = model.updateSingleAddress
    this.updateAddress = model.updateAddress
    this.deleteAddresses = model.deleteAddresses
    this.updateCart = model.updateCart
    this.clearCart = model.clearCart
    this.createOrder = model.createOrder

    this.getCartSupplierProductsCount = this.getCartSupplierProductsCount.bind(this)
    this.getCartEmail = this.getCartEmail.bind(this)
  }

  getCartSupplierProductsCount() {
    return this.modifiedCart?.suppliers?.length || 0
  }

  getCartEmail(fallbackEmail: string) {
    if (!this.cartState.cart) {
      return fallbackEmail
    }

    return selectCartAddressEmail(this.cartState.cart) || fallbackEmail
  }
}

export default Cart
