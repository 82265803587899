import { useState } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { Customer, CustomerPropertyList, CustomerRelationList, Product, Status } from '../utils/types/Product'
import { useAuth } from './useAuth'
import { useApi } from './useApi'
import { ALERT_DURATION, ProductBaseIncludes } from '../utils/constants'
import { useCustomer } from '../components/BuyerProfile/Account/CustomerProvider'
import {
  selectCustomerCount,
  selectNormalizedCustomer,
} from '../components/BuyerProfile/Account/selectors'
import { useCart } from '../components/Cart/CartProvider'
import useBuyerCatalogEvents from './analytics/useBuyerCatalogEvents'
import { triggerBuyerCatalogAddToCartAnalyticsEvent } from '../utils/analytics/utils'
import { OrderList, OrderListItem, OrderListItemProduct } from '../utils/types/buyerCatalogs'
import { getPrices } from '../lib/getPrices'
import { CartActionResponse } from '../utils/types/Cart'

interface UseBuyerCatalogs {
  listItems: CustomerRelationList[]
  orderLists: OrderList[]
  orderListsCount: number
  isLoading: boolean
  isSaving: boolean
  errorMessage: string
  selectedOrderList: OrderList | null
  totalProducts: number
  addOrderListToCart: (listName: string, ids?: string[]) => Promise<CartActionResponse>
  addProductToOrderList: (listName: string, data: Pick<OrderListItemProduct, 'product' | 'quantity'>) => Promise<void>
  updateProductFromOrderList: (listName: string, data: Pick<OrderListItemProduct, 'product' | 'quantity' | 'notes'>, prodId?: string) => Promise<void>
  deleteProductFromOrderList: (listName: string, ids: Product[]) => Promise<void>
  createOrderList: (listName: string) => Promise<void>
  renameOrderList: (currentName: string, newName: string) => Promise<void>
  deleteOrderList: (listName: string) => Promise<void>
}

const useBuyerCatalogs = (name?: string): UseBuyerCatalogs => {
  const [errorMessage, setErrorMessage] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const { getResource, createResource, updateResource, deleteResource } = useApi()
  const { addProduct, refresh: refreshCart } = useCart()
  const { user } = useAuth()

  // Analytics events
  const {
    createBuyerCatalogEvent,
    deleteBuyerCatalogEvent,
    renameBuyerCatalogEvent,
    addItemBuyerCatalogEvent,
    deleteItemBuyerCatalogEvent,
    updateItemBuyerCatalogEvent,
    singleAddToCartBuyerCatalogEvent,
    addToCartBuyerCatalogEvent,
  } = useBuyerCatalogEvents()

  const { t } = useTranslation('products')

  const { customerResponse, isLoading: isLoadingCustomer } = useCustomer()
  const customerCount = selectCustomerCount(customerResponse)

  const customerNames = Array
    .from({ length: customerCount })
    .reduce<Record<string, { owner: string, ownerStatus: Status }>>((map, _, index) => {
    const {
      id,
      fullName,
      status,
    } = selectNormalizedCustomer(customerResponse, index)

    if (!(id && fullName)) {
      return map
    }

    return {
      ...map,
      [id]: { owner: fullName, ownerStatus: status ?? 1 },
    }
  }, {})

  const {
    data: customerPropertyItems,
    isLoading: isCustomerPropertyItemsLoading,
    links: propertyLinks,
    refresh: refreshNames,
  } = getResource<CustomerPropertyList>(user ? 'customer' : null, '&related=property')

  const {
    data: allCustomerListItems,
    isLoading: isSavedProductsRequestLoading,
    links: listItemLinks,
    refresh,
  } = getResource<CustomerRelationList>(user ? 'customer' : null, '&related=relationships&include=product,customer/customer')

  const orderListIdMap = customerPropertyItems
    .filter((propertyItem) => propertyItem['customer.property.type'] === 'order-list')
    .reduce<Record<string, OrderList>>((map, propertyItem) => ({
    ...map,
    [propertyItem['customer.property.id']]: {
      id: propertyItem['customer.property.id'],
      name: propertyItem['customer.property.value'],
      editor: propertyItem['customer.property.editor'],
      ...customerNames[propertyItem['customer.property.parentid']],
      items: [],
    },
  }), {})

  const orderListNameMap = Object.entries(orderListIdMap)
    .reduce<Record<string, OrderList>>((map, [, orderList]) => ({
    ...map,
    [orderList.name]: orderList,
  }), {})

  const listItems = allCustomerListItems.filter((item) => item['customer.lists.type'].startsWith('order-list'))
  const productIds = listItems.map((ele) => ele['customer.lists.refid'])
  const prodsFilter = productIds ? `&ids=${(productIds).join(',')}` : ''
  const productsQuery = `include=${ProductBaseIncludes.join(',')}${prodsFilter}`

  const { data: products, links, isLoading: isProductsLoading } = getResource<Product>(
    productIds.length ? 'product' : null,
    productsQuery,
    { initialSize: 100, countrySpecific: false },
  )

  const productMap = products.reduce<Record<string, Product>>((map, product) => ({
    ...map,
    [product.id]: product,
  }), {})

  const orderLists = listItems.reduce((list, listItem) => {
    const { orderListId: modifiedListId, quantity, notes = '', sourcingRowData } = listItem['customer.lists.config'] ?? {}
    const modifiedOrderListName = orderListIdMap[modifiedListId ?? '']?.name

    // Could not find list matching id, or is sourcing row
    if (!modifiedListId || !quantity || !!sourcingRowData) {
      return list
    }

    const modifiedOrderList = list[modifiedOrderListName ?? '']

    if (!modifiedOrderList) {
      return list
    }

    const orderListItem: OrderListItem = {
      productId: listItem['customer.lists.refid'],
      ...customerNames[listItem['customer.lists.parentid']],
      editor: listItem['customer.lists.editor'],
      product: productMap[listItem['customer.lists.refid']],
      quantity,
      notes,
    }

    return {
      ...list,
      [modifiedOrderList.name]: {
        ...modifiedOrderList,
        items: [...modifiedOrderList.items, orderListItem],
      },
    }
  }, orderListNameMap)

  const selectedOrderList = name ? orderLists[name] : null

  const addProductToOrderList: UseBuyerCatalogs['addProductToOrderList'] = async (orderListName, data) => {
    const listName = orderListName.trim()
    setIsSaving(true)

    const { product, quantity } = data
    const productId = product.id
    const productSku = product['product.code']

    let listId = orderListNameMap[listName]?.id
    const isNewOrderList = !listId

    if (isNewOrderList) {
      const userPropertyData = [{
        'customer.property.value': listName,
        'customer.property.type': 'order-list',
      }]

      try {
        const [response,,] = await createResource<CustomerPropertyList, Partial<CustomerPropertyList>>('customer', userPropertyData, '&related=property')

        const newId = [response].flat().find((prop) => prop['customer.property.value'] === listName)?.id

        if (newId) {
          listId = newId
        }
        refreshNames()

        // Analytics event
        createBuyerCatalogEvent(listName, listId, productSku)
      } catch (e) {
        setErrorMessage(t('Oh no, failed to create catalog'))
        setTimeout(() => setErrorMessage(''), ALERT_DURATION)
        console.error(e)
        return
      }
    }

    const userRelationData: Partial<CustomerRelationList>[] = [{
      'customer.lists.refid': productId,
      'customer.lists.domain': 'product',
      'customer.lists.type': `order-list/${listId}`,
      'customer.lists.datestart': null,
      'customer.lists.dateend': null,
      'customer.lists.config': {
        orderListId: listId,
        quantity,
      },
      'customer.lists.position': 0,
      'customer.lists.status': 1,
      'customer.lists.parentid': '',
    }]

    try {
      const [,,response] = await createResource<Customer, Partial<CustomerRelationList>>('customer', userRelationData, '&related=relationships')

      const respError = response.customer
        ?.find((customer) => customer.errors)?.errors?.[0]?.title
        || response?.customer?.[0]?.exception
        || ''

      if (respError) {
        throw new Error(respError)
      } else {
        // Analytics event
        addItemBuyerCatalogEvent(listName, listId, productSku)
      }

      // Refresh data to get new customer relation id
      refresh()
    } catch (e) {
      setErrorMessage(t('Oh no, failed to save product'))
      setTimeout(() => setErrorMessage(''), ALERT_DURATION)
      console.error(e)
      throw e
    }
    setIsSaving(false)
  }

  const addOrderListToCart: UseBuyerCatalogs['addOrderListToCart'] = async (listName: string, ids?: string[]) => {
    setIsSaving(true)
    const orderList = orderLists[listName.trim()]
    const addToCartData = orderList.items
      .filter((item) => ids?.includes(item.productId) ?? true)
      // Filter out unavailable and RFQ only products
      .filter((item) => !!item.product && getPrices(item.product, 1).default.price)
      .flatMap((item) => {
        // All should have product defined, but this is needed for the type narrow
        const { product } = item
        if (!product) {
          return []
        }
        const supplierId = product.supplier[0].id
        // Empty string is main product quantity -> it's variants
        if (!item.quantity['']) {
          return Object.entries(item.quantity).map(([savedVariantId, quantity]) => {
            const currentVariant = product.product
              ?.find((variant) => (
                // Previously the variant label was saved instead of variant attribute id,
                // so we need to match the label for old data
                variant.attribute
                  .flatMap((attribute) => attribute.id)
                  .includes(savedVariantId)
                || variant.attribute
                  .flatMap((attribute) => attribute['attribute.label'])
                  .includes(savedVariantId)
                || variant['product.label'].includes(savedVariantId)
              ))
            const variantId = currentVariant?.attribute.flatMap((attribute) => attribute['attribute.id'])?.[0] || savedVariantId
            return {
              id: item.productId,
              supplierId,
              quantity,
              variant: variantId,
            }
          })
        }

        // Otherwise only a single quantity, so no variants
        return {
          id: item.productId,
          supplierId,
          quantity: item.quantity[''],
        }
      })

    const link = typeof links[0]['basket/product'] === 'string'
      ? links[0]['basket/product']
      : links[0]['basket/product'].href

    const addResponse = await addProduct(link, addToCartData)

    setIsSaving(false)

    // Analytics events
    triggerBuyerCatalogAddToCartAnalyticsEvent(
      orderList,
      ids,
      singleAddToCartBuyerCatalogEvent,
      addToCartBuyerCatalogEvent,
      refreshCart,
    )

    return addResponse
  }

  const updateProductFromOrderList: UseBuyerCatalogs['updateProductFromOrderList'] = async (listName, data, prodId) => {
    setIsSaving(true)
    const { product, quantity, notes = '' } = data
    const productId = product?.id || prodId
    const productSku = product?.['product.code']
    const listId = orderLists[listName.trim()]?.id

    const existingProductListItem = listItems.find((item) => (
      item['customer.lists.refid'] === productId && item['customer.lists.type'] === `order-list/${listId}`))

    if (existingProductListItem) {
      const updatedListItem = {
        ...existingProductListItem,
        'customer.lists.config': {
          orderListId: listId,
          quantity,
          notes,
        },
      }

      const itemLink = listItemLinks.find((link) => {
        const linkString = typeof link.self === 'string' ? link.self : link.self.href
        return linkString.includes(`relatedid=${existingProductListItem.id}`)
      })?.self

      const linkString = typeof itemLink === 'string' ? itemLink : itemLink?.href
      const linkQuery = linkString?.split('?')[1]

      if (linkQuery) {
        await updateResource('customer', updatedListItem, `&include=customer/lists,customer/customer,product&${linkQuery}`)
        refresh()

        // Analytics event
        updateItemBuyerCatalogEvent(listName, listId, productSku)
      }
    }
    setIsSaving(false)
  }

  const deleteProductFromOrderList: UseBuyerCatalogs['deleteProductFromOrderList'] = async (listName, productArray) => {
    setIsSaving(true)
    await Promise.all(productArray.map(async (product) => {
      const productId = product.id
      const productSku = product['product.code']
      const listId = orderLists[listName.trim()]?.id
      const existingProductListItem = listItems.find((item) => (
        item['customer.lists.refid'] === productId && item['customer.lists.type'] === `order-list/${listId}`))

      if (existingProductListItem) {
        const itemLink = listItemLinks.find((link) => {
          const linkString = typeof link.self === 'string' ? link.self : link.self.href
          return linkString.includes(`relatedid=${existingProductListItem.id}`)
        })?.self

        const linkString = typeof itemLink === 'string' ? itemLink : itemLink?.href
        const linkQuery = linkString?.split('?')[1]

        if (linkQuery) {
          await deleteResource('customer', `&include=customer/lists,customer/customer,product&${linkQuery}`)
          refresh()

          // Analytics event
          deleteItemBuyerCatalogEvent(listName, listId, productSku)
        }
      }
    }))
    setIsSaving(false)
  }

  const createOrderList: UseBuyerCatalogs['createOrderList'] = async (listName) => {
    setIsSaving(true)
    const userPropertyData = [{
      'customer.property.value': listName.trim(),
      'customer.property.type': 'order-list',
    }]

    try {
      const [
        propertyList, , response,
      ] = await createResource<CustomerPropertyList, Partial<CustomerPropertyList>>('customer', userPropertyData, '&related=property')

      const newListId = Array.isArray(propertyList)
        ? propertyList.find((resource) => resource['customer.property.value'] === listName)?.['customer.property.id']
        : propertyList['customer.property.id']

      refresh()
      refreshNames()

      const respError = response.customer
        ?.find((customer) => customer.errors)?.errors?.[0]?.title
        || response?.customer?.[0]?.exception
        || ''

      if (respError) {
        setErrorMessage(t('Oh no, failed to save product'))
        setTimeout(() => setErrorMessage(''), ALERT_DURATION)
        console.error(respError)
      } else {
        // Analytics event
        createBuyerCatalogEvent(listName, newListId || '')
      }
    } catch (e) {
      setErrorMessage(t('Oh no, failed to save product'))
      setTimeout(() => setErrorMessage(''), ALERT_DURATION)
      console.error(e)
    }
    setIsSaving(false)
  }

  const renameOrderList: UseBuyerCatalogs['renameOrderList'] = async (currentName, newName) => {
    setIsSaving(true)
    const listId = orderLists[currentName]?.id
    const updatedPropItem = {
      'customer.property.value': newName.trim(),
    }
    const itemLink = propertyLinks.find((link) => {
      const linkString = typeof link.self === 'string' ? link.self : link.self.href
      return linkString.includes(`relatedid=${listId}`)
    })?.self
    const linkString = typeof itemLink === 'string' ? itemLink : itemLink?.href
    const linkQuery = linkString?.split('?')[1]
    if (linkQuery) {
      try {
        await updateResource<CustomerPropertyList, Partial<CustomerPropertyList>>('customer', updatedPropItem, `&include=customer/lists,customer/customer,product&${linkQuery}`)
        refreshNames()

        // Analytics event
        renameBuyerCatalogEvent(`${currentName} => ${newName}`, listId)
      } catch (e) {
        setErrorMessage(t('Oh no, failed to rename catalog'))
        setTimeout(() => setErrorMessage(''), ALERT_DURATION)
        console.error(e)
      }
    }
    setIsSaving(false)
  }

  const deleteOrderList: UseBuyerCatalogs['deleteOrderList'] = async (listName) => {
    setIsSaving(true)
    const listId = orderLists[listName.trim()]?.id
    const itemLink = propertyLinks.find((link) => {
      const linkString = typeof link.self === 'string' ? link.self : link.self.href
      return linkString.includes(`relatedid=${listId}`)
    })?.self
    const linkString = typeof itemLink === 'string' ? itemLink : itemLink?.href
    const linkQuery = linkString?.split('?')[1]
    if (linkQuery) {
      try {
        await deleteResource('customer', `&include=customer/lists,customer/customer,product&${linkQuery}`)
        refreshNames()

        // Analytics event
        deleteBuyerCatalogEvent(listName, listId)
      } catch (e) {
        setErrorMessage(t('Oh no, failed to rename catalog'))
        setTimeout(() => setErrorMessage(''), ALERT_DURATION)
        console.error(e)
      }
    }
    setIsSaving(false)
  }

  // Get the list of only unique product ids from all catalogs
  const getOrderListTotalProducts = (list: OrderList[]) => [
    ...new Set(
      list.flatMap((item) => item.items.map((product) => product.productId)),
    ),
  ]

  const isLoading = isLoadingCustomer
  || isProductsLoading
  || isSavedProductsRequestLoading
  || isCustomerPropertyItemsLoading

  return {
    orderLists: Object.values(orderLists),
    orderListsCount: Object.values(orderLists).length,
    totalProducts: getOrderListTotalProducts(Object.values(orderLists)).length,
    isLoading,
    isSaving,
    listItems,
    errorMessage,
    selectedOrderList,
    addOrderListToCart,
    addProductToOrderList,
    updateProductFromOrderList,
    deleteProductFromOrderList,
    createOrderList,
    renameOrderList,
    deleteOrderList,
  }
}

export default useBuyerCatalogs
