import { libraryFunctions } from '../../../lib/resources/price'
import { getSalesUnit } from '../../../lib/salesUnit'
import { getProductName } from '../../../lib/text'
import { Product } from '../../../utils/types/Product'

const { getDefaultPrice } = libraryFunctions

interface SingleProductModel {
  product: Product

  isRouterReady?: boolean

  handleAddToCart?: () => void

  openSampleRequestModal?: () => void

  openDocumentRequestModal?: () => void
}

class SingleProduct {
  product: SingleProductModel['product']

  isRouterReady: SingleProductModel['isRouterReady']

  handleAddToCart: SingleProductModel['handleAddToCart']

  openSampleRequestModal: SingleProductModel['openSampleRequestModal']

  openDocumentRequestModal: SingleProductModel['openDocumentRequestModal']

  constructor(model: SingleProductModel) {
    const {
      product,
      isRouterReady,
      handleAddToCart,
      openSampleRequestModal,
      openDocumentRequestModal,
    } = model

    this.product = product
    this.isRouterReady = isRouterReady
    this.handleAddToCart = handleAddToCart
    this.openSampleRequestModal = openSampleRequestModal
    this.openDocumentRequestModal = openDocumentRequestModal

    this.getProductName = this.getProductName.bind(this)
    this.getSalesUnit = this.getSalesUnit.bind(this)
    this.getProductMoq = this.getProductMoq.bind(this)
  }

  getProductName() {
    return getProductName(this.product)
  }

  getSalesUnit() {
    return getSalesUnit(this.product)
  }

  getProductMoq() {
    return getDefaultPrice(this.product.price)?.['price.quantity'] || 1
  }
}

export default SingleProduct
