import { Catalog } from '../../../utils/types/Product'

interface CategoryPickerModel {
  catalogs: Catalog[]
  handleChange: (value: string) => void
}

class CategoryPicker {
  catalogs: CategoryPickerModel['catalogs']

  handleChange: CategoryPickerModel['handleChange']

  constructor(model: CategoryPickerModel) {
    this.catalogs = model.catalogs
    this.handleChange = model.handleChange
  }
}

export default CategoryPicker
