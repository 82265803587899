import { FC } from 'react'
import { useRouter } from 'next/router'
import { FlexboxGrid } from 'rsuite'

import { translateBaseUrl } from '../../../../lib/urls'
import { CountryData, CountryPickerType, LOCAL_STORAGE_COUNTRY_KEY, ScreenSize } from '../../../../utils/constants'
import { renderFlag } from '../../../../utils/countries'
import { generateCountryAndLocaleString, getCountryAndLocaleStrings } from '../../../../utils/locales'
import { Locale } from '../../../../external'
import useIsOnMobile from '../../../../services/useIsOnMobile'
import useTrackingContext from '../../../../services/trackingContext/useTrackingContext'

import styles from '../../../../styles/CountryPicker.module.less'

interface CountryPickerProps {
  handleSelect?: () => void
  headerText?: string
}

const CountryPickerContent: FC<CountryPickerProps> = (props) => {
  const { headerText, handleSelect = () => {} } = props

  const fullRouter = useRouter() // Full router passed as param elsewhere
  const isOnMobile = useIsOnMobile(ScreenSize.md)

  // Used to translate single product page urls
  const { state: productState } = useTrackingContext()
  const localizedProductUrls = productState?.util?.localizedUrls

  const {
    push,
    locale: countryAndLocale,
    defaultLocale: defaultCountryAndLocale,
  } = fullRouter

  const {
    country,
    locale: lang,
  } = getCountryAndLocaleStrings(countryAndLocale || defaultCountryAndLocale)

  const isSelectedCountry = (countryId: Locale) => countryId === country

  const isSelectedLocale = (
    locale: Locale,
    countryId: Locale,
  ) => locale === lang && isSelectedCountry(countryId)

  // To keep the selected country at top
  const sortCountryPicker = () => [
    CountryData.find((countryItem) => isSelectedCountry(countryItem.locale)) ?? CountryData[0],
    ...CountryData.filter((countryItem) => !isSelectedCountry(countryItem.locale)),
  ]

  const countrySelect = async (countryItem: CountryPickerType) => {
    let selectedLang = lang
    // If the current language does not exists in the selected country locales, set the default
    // first language as the selected language
    if (!countryItem.locales.includes(selectedLang)) {
      selectedLang = countryItem?.locales[0]
    }

    const newCountryAndLocale = generateCountryAndLocaleString(countryItem.locale, selectedLang)
    const baseUrl = await translateBaseUrl(
      fullRouter,
      newCountryAndLocale,
      localizedProductUrls,
    )

    localStorage.setItem(LOCAL_STORAGE_COUNTRY_KEY, newCountryAndLocale)

    handleSelect()

    push(
      baseUrl,
      undefined,
      { locale: generateCountryAndLocaleString(countryItem.locale, selectedLang) },
    )
  }

  const localeSelect = async (selectedLang: Locale, countryItem: CountryPickerType) => {
    if (!isSelectedLocale(selectedLang, countryItem.locale)) {
      const newCountryAndLocale = generateCountryAndLocaleString(countryItem.locale, selectedLang)
      const baseUrl = await translateBaseUrl(
        fullRouter,
        newCountryAndLocale,
        localizedProductUrls,
      )

      localStorage.setItem(LOCAL_STORAGE_COUNTRY_KEY, newCountryAndLocale)

      handleSelect()

      push(
        baseUrl,
        undefined,
        { locale: generateCountryAndLocaleString(countryItem.locale, selectedLang) },
      )
    }
  }

  return (
    <>
      {headerText && (
        <p className="h6 margin-left-spacer">
          {headerText}
          :
        </p>
      )}
      {sortCountryPicker().map((countryObj) => (
        <FlexboxGrid
          align="middle"
          justify="space-between"
          className={`margin-top-spacer
            ${isOnMobile ? 'margin-bottom-spacer-double' : ''}
            ${styles['country-picker-content']}
          `}
          key={countryObj.name}
        >
          <FlexboxGrid.Item>
            <FlexboxGrid align="middle">
              <FlexboxGrid.Item>
                {renderFlag(countryObj.locale)}
              </FlexboxGrid.Item>
              <FlexboxGrid.Item
                data-testid={`country-picker-${countryObj.locale}`}
                className={`
                    margin-left-spacer-half
                    ${styles[isSelectedCountry(countryObj.locale) ? 'selected-country' : 'pointer']}
                `}
                onClick={() => countrySelect(countryObj)}
              >
                {countryObj.native}
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item className={styles.locales}>
            <FlexboxGrid>
              {countryObj.locales.map((locale) => (
                <FlexboxGrid.Item
                  data-testid={`locale-picker-${countryObj.locale}-${locale}`}
                  className={`margin-left-spacer ${
                    styles[isSelectedLocale(locale, countryObj.locale)
                      ? 'selected-lang'
                      : 'pointer']}`}
                  key={locale}
                  onClick={() => localeSelect(locale, countryObj)}
                >
                  {locale.toLocaleUpperCase()}
                </FlexboxGrid.Item>
              )) }
            </FlexboxGrid>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      )) }
    </>
  )
}

export default CountryPickerContent
