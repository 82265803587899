import { type ProductQuantity as IProductQuantity, Product } from '../../../utils/types/Product'

interface ProductQuantityModel {
  product?: Product
  productQuantity?: IProductQuantity[]
  moq?: number
  setProductQuantity?: (productQuantity: IProductQuantity[]) => void
  handleQuantityChange?: (variantID: string, type: 'increment' | 'decrement') => void
}

class ProductQuantity {
  product: ProductQuantityModel['product']

  productQuantity: ProductQuantityModel['productQuantity']

  moq: ProductQuantityModel['moq']

  setProductQuantity: ProductQuantityModel['setProductQuantity']

  handleQuantityChange: ProductQuantityModel['handleQuantityChange']

  constructor(model: ProductQuantityModel) {
    this.product = model.product
    this.productQuantity = model.productQuantity
    this.moq = model.moq
    this.setProductQuantity = model.setProductQuantity
    this.handleQuantityChange = model.handleQuantityChange
  }
}

export default ProductQuantity
