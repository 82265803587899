import TagManager from 'react-gtm-module'

import { CustomerType } from '../../components/CustomerTypeSelection'

interface SelectCustomerTypeModal {
  context: string
  customerType: CustomerType
}

const selectCustomerTypeEvent = ({ context, customerType }: SelectCustomerTypeModal) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'select_customer_type',
      customer_type: customerType,
      context,
    },
  })
}

export default selectCustomerTypeEvent
