import { ImageLoaderProps } from 'next/legacy/image'

import { ProductMedia } from './types/Product'

const defaultPreviewSizes = {
  // From aimeos configuration
  small: '240',
  medium: '720',
  large: '2160',
}

export const imageLoader = (loaderProps: Omit<ImageLoaderProps, 'config'> | string): string => {
  const staticUrl = process.env.NEXT_PUBLIC_STATIC_URL
  if (typeof loaderProps === 'string') {
    return loaderProps.startsWith('http') ? loaderProps : `${staticUrl}/${loaderProps}`
  }
  return loaderProps?.src?.startsWith('http') ? loaderProps.src : `${staticUrl}/${loaderProps?.src || ''}`
}

export const cmsImageLoader = (loaderProps: Omit<ImageLoaderProps, 'config'> | string): string => {
  const cmsUrL = process.env.NEXT_PUBLIC_CMS_BASE
  if (typeof loaderProps === 'string') {
    return loaderProps.startsWith('http') ? loaderProps : `${cmsUrL}/${loaderProps}`
  }
  return loaderProps.src.startsWith('http') ? loaderProps.src : `${cmsUrL}/${loaderProps.src}`
}

export const getSmallPreview = (media?: ProductMedia): string => {
  if (!media || !media['media.preview']) return ''
  return media['media.preview'] // media.preview is always smallest preview
}

export const getMediumPreview = (media?: ProductMedia): string => {
  if (!media || !media['media.previews']) return ''
  const previewSizes = Object.keys(media['media.previews'])
  const [previewKey] = previewSizes.includes(defaultPreviewSizes.medium)
    ? [defaultPreviewSizes.medium]
    : previewSizes.sort().slice(-1)
  return media['media.previews'][previewKey]
}

export const getLargePreview = (media?: ProductMedia): string => {
  if (!media || !media['media.previews']) return ''
  const previewSizes = Object.keys(media['media.previews'])
  const [previewKey] = previewSizes.includes(defaultPreviewSizes.large)
    ? [defaultPreviewSizes.large]
    : previewSizes.sort().slice(-1)
  return media['media.previews'][previewKey]
}
