import {
  CurrencyId,
  CurrencySymbol,
  PriceTag,
  VariantType,
} from './types/Product'
import { CountryAndLocale, HreflangFormat, Locale } from '../external'

export const TOTAL_SATISFIED_CUSTOMERS = '12,000'

export const ALERT_DURATION = 7000

export const DEFAULT_CURRENCY = 'EUR'

export const TOP_BUTTON_OFFSET = 600

export const SHIPPING_COST_DEFAULT = 0

export const SHIPPING_LIMIT_DEFAULT = 0

export const SHIPMENT_DAYS_DEFAULT = 30

export const PRODUCTS_PER_SUPPLIER = 9

export const SUPPLIERS_TO_SHOW = 9

export const SUPPLIERS_TO_FETCH_ON_CLIENT = 50

export const PRODUCT_FAULTY_CLICK_THRESHOLD = 2000

export const TOTAL_MARKETPLACE_PRODUCTS = '22 353'

export const TOTAL_MARKETPLACE_BRANDS = '150'

export const HOVER_DELAY = 500

export const DEFAULT_TAX_RATE_PERCENTAGE = 0.24

export const SSG_PRODUCTS_PER_PAGE = '16'
export const PRODUCTS_PER_PAGE = '52'
export const PRODUCTS_PER_SHOP_PAGE = '12'

export const QUARTERLY_ORDER_VOLUME_THRESHOLDS: {
  [key in CurrencySymbol]: number
} = {
  '€': 500,
  kr: 5950,
}

export const Currency: { [key in CurrencyId]: CurrencySymbol } = {
  EUR: '€',
  SEK: 'kr',
}

export const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export const INVITE_LATER_KEY = 'onBoarding_inviteLater'

export const SKIP_VERIFICATION_KEY = 'onBoarding_skipEmailVerification'

export const VARIANT_TYPE = 'variant-type'

export const LOCAL_STORAGE_COUNTRY_KEY = 'user_matched_country'

export const LOCAL_STORAGE_USER_KEY = 'user'

export const LOCAL_STORAGE_SUPER_USER_KEY = 'superuser'

export const SESSION_STORAGE_INVITE_EMAIL_KEY = 'invite_email'

export const SESSION_STORAGE_INVITE_COMPANY_KEY = 'invite_company'

export const SESSION_STORAGE_INVITE_TOKEN_KEY = 'invite_token'

// Used for 'unsensitive' client side encryptions
// such as creating hashes for internal analytics events.
export const CLIENT_ENCRYPTION_SALT = 'droppe-one'

export const DEFAULT_COOKIE_EXPIRATION_DAYS = 365

export const DEFAULT_COOKIE_DOMAIN = '.droppe.com'

export const INTERNAL_TRAFFIC_COOKIE_NAME = 'isInternalTraffic'

export const DROPPE_EMAIL_REGEX = /^\w+([.-]?\w+)*@droppe.fi$/

// Url hashes for opening modals
export const MODAL_HASHES = {
  RFQ_URL_HASH: '#quote',
  SAMPLE_REQUEST_URL_HASH: '#sample',
  CONTACT_URL_HASH: '#contact',
  DOCUMENT_REQUEST_URL_HASH: '#document',
  LOGIN_URL_HASH: '#login',
  REGISTER_URL_HASH: '#register',
  INVITE_URL_HASH: '#invite',
  SELECT_CUSTOMER_TYPE_URL_HASH: '#select-customer-type',
} as const

export type ModalUrlHash = typeof MODAL_HASHES[keyof typeof MODAL_HASHES]

export const PREVIOUS_URL_STORAGE_KEY = 'previousUrl'
export const LOGIN_MODAL_TRIGGERED_PLACE_KEY = 'loginModalTriggeredPlace'

export const ORDER_CONFIRMATION_API_URL = '/api/send-order-confirmation'
export const GENERATE_USER_ID_URL = '/api/generate-user-id'

export const ORTTO_CREATE_ACTIVITY_URL = 'https://api.eu.ap3api.com/v1/activities/create'

export const ORTTO_SEND_TRANSACTIONAL_EMAIL_URL = 'https://api.eu.ap3api.com/v1/transactional/send'

export enum LoginModalTriggeredPlace {
  headerHeart = 'header-heart',
  productHeart = 'product-heart',
}

export const SELECTED_CUSTOMER_ID_STORAGE_KEY = 'selectedCustomerId'

/**
 * List of static query param names
 */
export enum QueryParam {
  search = 'search',
  manageSearch = 'manage_search',
  sort = 'sort',
  filters = 'filters',
  page = 'page',
  supplierCode = 'supplierCode',
  site = 'site',
  manageSearchCat1 = 'cat1',
  manageSearchCat2 = 'cat2',
  manageSearchCat3 = 'cat3',
  manageSearchCat4 = 'cat4',
  id = 'id',
}

export enum SortOption {
  popular = 'popular',
  newest = 'newest',
  lowest = 'lowest',
  quote_only = 'quote-only',
  highest = 'highest',
  name_az = 'name-az',
  name_za = 'name-za',
}

export const ResourceTag = {
  product: 'product',
  supplier: 'supplier',
  catalog: 'catalog',
} as const

export const Country = {
  af: 'Afghanistan',
  ax: 'Åland Islands',
  dz: 'Algeria',
  ao: 'Angola',
  al: 'Albania',
  ag: 'Antigua and Barbuda',
  ar: 'Argentina',
  aw: 'Aruba',
  am: 'Armenia',
  at: 'Austria',
  au: 'Australia',
  az: 'Azerbaijan',
  ad: 'Andorra',
  bh: 'Bahrain',
  bs: 'Bahamas',
  bd: 'Bangladesh',
  bb: 'Barbados',
  by: 'Belarus',
  be: 'Belgium',
  bm: 'Bermuda',
  bt: 'Bhutan',
  bv: 'Bouvet Island',
  br: 'Brazil',
  bz: 'Belize',
  bn: 'Brunei',
  bj: 'Benin',
  ba: 'Bosnia and Herzegovina',
  bo: 'Bolivia',
  bw: 'Botswana',
  bg: 'Bulgaria',
  bf: 'Burkina Faso',
  bi: 'Burundi',
  kh: 'Cambodia',
  cm: 'Cameroon',
  ca: 'Canada',
  ky: 'Cayman Islands',
  cv: 'Cape Verde',
  cf: 'Central African Republic',
  td: 'Chad',
  cn: 'China',
  km: 'Comoros',
  co: 'Colombia',
  cr: 'Costa Rica',
  hr: 'Croatia',
  cw: 'Curaçao',
  cu: 'Cuba',
  cy: 'Cyprus',
  cz: 'Czechia',
  dk: 'Denmark',
  dj: 'Djibouti',
  do: 'Dominican Republic',
  cd: 'DR Congo',
  ec: 'Ecuador',
  eg: 'Egypt',
  gq: 'Equatorial Guinea',
  er: 'Eritrea',
  ee: 'Estonia',
  sz: 'Eswatini',
  et: 'Ethiopia',
  fo: 'Faroe Islands',
  fi: 'Finland',
  fj: 'Fiji',
  fr: 'France',
  gm: 'Gambia',
  ge: 'Georgia',
  de: 'Germany',
  ga: 'Gabon',
  gh: 'Ghana',
  gi: 'Gibraltar',
  gd: 'Grenada',
  gr: 'Greece',
  gt: 'Guatemala',
  gy: 'Guyana',
  gn: 'Guinea',
  gw: 'Guinea-Bissau',
  ht: 'Haiti',
  hm: 'Heard Island and McDonald Islands',
  hk: 'Hong Kong',
  hn: 'Honduras',
  hu: 'Hungary',
  is: 'Iceland',
  in: 'India',
  id: 'Indonesia',
  ir: 'Iran',
  iq: 'Iraq',
  ie: 'Ireland',
  il: 'Israel',
  it: 'Italy',
  ci: 'Ivory Coast',
  jm: 'Jamaica',
  jp: 'Japan',
  je: 'Jersey',
  jo: 'Jordan',
  kz: 'Kazakhstan',
  ke: 'Kenya',
  ki: 'Kiribati',
  kw: 'Kuwait',
  kg: 'Kyrgyzstan',
  la: 'Laos',
  lb: 'Lebanon',
  ls: 'Lesotho',
  lv: 'Latvia',
  ly: 'Libya',
  lr: 'Liberia',
  li: 'Liechtenstein',
  lt: 'Lithuania',
  lu: 'Luxembourg',
  mg: 'Madagascar',
  mw: 'Malawi',
  mv: 'Maldives',
  my: 'Malaysia',
  ml: 'Mali',
  mt: 'Malta',
  yt: 'Mayotte',
  mh: 'Marshall Islands',
  mr: 'Mauritania',
  mu: 'Mauritius',
  mx: 'Mexico',
  mc: 'Monaco',
  mn: 'Mongolia',
  fm: 'Micronesia',
  md: 'Moldova',
  me: 'Montenegro',
  ma: 'Morocco',
  mz: 'Mozambique',
  mm: 'Myanmar',
  na: 'Namibia',
  nr: 'Nauru',
  np: 'Nepal',
  nl: 'Netherlands',
  nz: 'New Zealand',
  nu: 'Niue',
  ni: 'Nicaragua',
  ne: 'Niger',
  ng: 'Nigeria',
  kp: 'North Korea',
  mk: 'North Macedonia',
  no: 'Norway',
  om: 'Oman',
  pa: 'Panama',
  pg: 'Papua New Guinea',
  py: 'Paraguay',
  pk: 'Pakistan',
  pw: 'Palau',
  pe: 'Peru',
  ph: 'Philippines',
  pl: 'Poland',
  pt: 'Portugal',
  pr: 'Puerto Rico',
  qa: 'Qatar',
  cg: 'Republic of the Congo',
  re: 'Réunion',
  ro: 'Romania',
  ru: 'Russia',
  rw: 'Rwanda',
  ws: 'Samoa',
  kn: 'Saint Kitts and Nevis',
  lc: 'Saint Lucia',
  vc: 'Saint Vincent and the Grenadines',
  sm: 'San Marino',
  st: 'São Tomé and Príncipe',
  sa: 'Saudi Arabia',
  rs: 'Serbia',
  sn: 'Senegal',
  sc: 'Seychelles',
  sl: 'Sierra Leone',
  sg: 'Singapore',
  sk: 'Slovakia',
  si: 'Slovenia',
  za: 'South Africa',
  so: 'Somalia',
  sb: 'Solomon Islands',
  kr: 'South Korea',
  ss: 'South Sudan',
  es: 'Spain',
  lk: 'Sri Lanka',
  sr: 'Suriname',
  sj: 'Svalbard and Jan Mayen',
  sv: 'Sweden',
  ch: 'Switzerland',
  sy: 'Syria',
  tj: 'Tajikistan',
  tz: 'Tanzania',
  th: 'Thailand',
  tl: 'Timor-Leste',
  to: 'Tonga',
  tg: 'Togo',
  tt: 'Trinidad and Tobago',
  tr: 'Turkey',
  tm: 'Turkmenistan',
  tv: 'Tuvalu',
  ug: 'Uganda',
  ua: 'Ukraine',
  ae: 'United Arab Emirates',
  gb: 'United Kingdom',
  us: 'United States Virgin Islands',
  uy: 'Uruguay',
  uz: 'Uzbekistan',
  va: 'Vatican City',
  vu: 'Vanuatu',
  ve: 'Venezuela',
  vn: 'Vietnam',
  wf: 'Wallis and Futuna',
  eh: 'Western Sahara',
  ye: 'Yemen',
  zm: 'Zambia',
  zw: 'Zimbabwe',
} as const

export const VALID_LOCALE_LIST: Readonly<Locale[]> = [
  'fi',
  'sv',
  'de',
  'en',
] as const

export const RELEVANCE = 'relevance'

export type CountryId = keyof typeof Country

export type CountryName = (typeof Country)[CountryId]

export type ActiveCountryId = Extract<CountryId, 'fi' | 'sv' | 'de'>

export type TaxCountryId = Extract<CountryId, ActiveCountryId>

export type DroppeBusinessEntities = Record<
ActiveCountryId,
{
  entityName: string
  businessId: string
  phoneNumber: string
}
>

// Used in links' hreflang attributes for SEO
export const LOCALE_HREFLANG_MAP: Record<CountryAndLocale, HreflangFormat> = {
  'fi-fi': 'fi-FI',
  'fi-en': 'en-FI',
  'fi-sv': 'sv-FI',
  'sv-sv': 'sv-SE',
  'sv-en': 'en-SE',
  'de-de': 'de-DE',
  'de-en': 'en-DE',
}

export const VALID_LOCALE_COUNTRY: Record<ActiveCountryId, string> = {
  fi: Country.fi,
  sv: Country.sv,
  de: Country.de,
} as const

export enum Certificate {
  foodSafe = 'food-safe',
  ceCat = 'ce/md',
  ceCat1 = 'ce/ppe-cat-1',
  ceCat2 = 'ce/ppe-cat-2',
  ceCat3 = 'ce/ppe-cat-3',
}

export enum ZapierType {
  contact = 'contact us',
  outOfStock = 'Out of stock',
  inquiry_request = 'Inquiry request',
  confirm_order = 'Confirm order',
  supplier_inquiry = 'Supplier Inquiry',
  checkout = 'Checkout',
  rfq = 'Request for quote',
  sampleRequest = 'Sample Request',
  documentRequest = 'Document Request',
  emptyStateContactRequest = 'empty-state-contact-request',
  createSourcingRow = 'create-sourcing-row',
  addSuggestionSourcingRow = 'add-suggestion-sourcing-row',
  acceptSourcingRow = 'accept-sourcing-row',
  rejectSourcingRow = 'reject-sourcing-row',
}

export enum ZapierSuggestionType {
  featureType = 'feature type',
  featureTypeValue = 'feature type value',
  useCase = 'use case',
  label = 'label',
  standard = 'standard',
  standardVersion = 'standard version',
  technicalResult = 'technical result',
  category = 'category',
}

export type CountryPickerType = {
  name: CountryName
  native: string
  locale: ActiveCountryId
  locales: Locale[]
  currency: CurrencyId
}
export interface ContactCardType {
  name: 'Ossi' | 'Toni' | 'Henri' | 'Olli' | 'Niklas' | 'Tahir'
  phone: string
  categories: string[]
  photo: string
}

export type ContactCardDataType = {
  [name in Extract<Locale, ActiveCountryId>]: ContactCardType[]
}

export const CountryBusinessEntities: DroppeBusinessEntities = {
  fi: {
    entityName: 'Droppe Oy',
    businessId: '3130765-4',
    phoneNumber: '+358 45 4909908',
  },
  sv: {
    entityName: 'Droppe AB',
    businessId: '559360-1791',
    phoneNumber: '+46 76 692 4711',
  },
  de: {
    entityName: 'Droppe GmbH',
    businessId: 'HRB 174917',
    phoneNumber: '+49 1573 5981912',
  },
}

export const CountryData: CountryPickerType[] = [
  {
    name: 'Finland',
    native: 'Suomi',
    locale: 'fi',
    locales: ['fi', 'sv', 'en'],
    currency: 'EUR',
  },
  {
    name: 'Sweden',
    native: 'Sverige',
    locale: 'sv',
    locales: ['sv', 'en'],
    currency: 'SEK',
  },
  {
    name: 'Germany',
    native: 'Deutschland',
    locale: 'de',
    locales: ['de', 'en'],
    currency: 'EUR',
  },
]

export const activeCountryIds: ActiveCountryId[] = CountryData.map(
  (country) => country.locale,
)

const getSpecialistImagePath = (fileName: string) => `/productSpecialists/${fileName}`

export const ContactCardData: ContactCardDataType = {
  sv: [
    {
      name: 'Niklas',
      phone: '+46 76 692 47 11',
      categories: [],
      photo: getSpecialistImagePath('niklas-gahnstrom.jpg'),
    },
    {
      name: 'Toni',
      phone: '0766922954',
      categories: [
        'Consumables',
        'Kitchen equipment',
        'Work clothing',
        'Disposable clothing',
        'Gloves',
        'Head and eye protection',
        'Respiratory protection',
        'Hearing protection',
      ],
      photo: getSpecialistImagePath('toni-elonen.png'),
    },
  ],
  de: [
    {
      name: 'Tahir',
      phone: '+49 1573 5981912',
      categories: [],
      photo: getSpecialistImagePath('tahir-genc.jpeg'),
    },
  ],
  fi: [
    {
      name: 'Henri',
      phone: '+358 45 4909908',
      categories: [],
      photo: getSpecialistImagePath('henri-kamarainen.jpg'),
    },
    {
      name: 'Ossi',
      phone: '+358 45 4908111',
      categories: [
        'Head and eye protection',
        'Hearing protection',
        'Disposable clothing',
        'Gloves',
      ],
      photo: getSpecialistImagePath('uche-ohanwe.jpg'),
    },
    {
      name: 'Toni',
      phone: '+358 45 4908054',
      categories: [
        'Nitrile gloves',
        'Respiratory protection',
        'Hygiene & Hands',
      ],
      photo: getSpecialistImagePath('toni-ranta.jpg'),
    },
    {
      name: 'Olli',
      phone: '+358 45 4907911',
      categories: [
        'Vinyl gloves',
        'Consumables',
        'Kitchen equipment',
        'Work clothing',
      ],
      photo: getSpecialistImagePath('olli-kettunen.jpg'),
    },
  ],
}

export const VATs: Record<TaxCountryId, number> = {
  fi: 25.5,
  sv: 25.0,
  de: 19.0,
}

export enum InputFieldTypes {
  Input = 'input',
  Textarea = 'textarea',
  Postal = 'postal',
  FullName = 'fullname',
  CompanyName = 'company',
  Role = 'role',
}

export const INPUT_FIELDS_MAX_LENGTH: Record<InputFieldTypes, number> = {
  input: 100,
  textarea: 500,
  postal: 12,
  fullname: 128,
  company: 100,
  role: 64,
}

export const VariantTypes: VariantType[] = [
  { value: 'size', label: 'Size' },
  { value: 'color', label: 'Color' },
]

export const PRODUCT_ORDER_PRICE_LIMIT = {
  EUR: 20_000,
  SEK: 210_000,
}

export const ProductBaseIncludes = [
  'product/attribute',
  'attribute/attribute',
  'product/media',
  'price',
  'product',
  'product/property',
  'text',
  'catalog',
  'supplier',
  'supplier/address',
  'supplier/media',
  'supplier/service',
  'stock',
  'attribute/property',
] as const

export const CatalogBaseIncludes = [
  'catalog',
  'media',
  'catalog/text',
  'catalog/attribute',
] as const

export const TOP_LEVEL_CATEGORIES_QUERY = `include=${CatalogBaseIncludes.join(',')}&filter[%3D%3D][catalog.level]=1&fields[attribute.lists]&fields[catalog.lists]&fields[catalog]=catalog.code,catalog.config,catalog.level,catalog.label`

export const ProductListFieldsQuery = '&fields[product.lists]=product.lists.id,product.lists.type&fields[attribute.lists]&fields[catalog.lists]&fields[supplier.lists]'

export const PACKAGE_PIECES_DATA = ['100', '250', '500', '1000']

export const CMS_BASE_URL = process.env.NEXT_PUBLIC_CMS_BASE

export const isDeleteDisabled = process.env.NEXT_PUBLIC_DISABLE_EDIT_PRODUCT_DELETE

export const SUPPLIER_TERMS_PATH = '/supplier-terms'

/**
 * A dictionary of all the localized translation sort values, normalized to the en version
 */
export const localizedSortValuesDict: Readonly<Record<string, SortOption>> = {
  // Always in the order of fi, sv, de
  // 'name a-z':
  'nimi-az': SortOption.name_az,
  'namn-az': SortOption.name_az,
  'name-az': SortOption.name_az,
  // 'name z-a':
  'nimi-za': SortOption.name_za,
  'namn-za': SortOption.name_za,
  'name-za': SortOption.name_za,
  // 'newest':
  uusin: SortOption.newest,
  nyaste: SortOption.newest,
  neueste: SortOption.newest,
  // 'highest':
  ylin: SortOption.highest,
  hogsta: SortOption.highest,
  hochste: SortOption.highest,
  // 'lowest':
  alin: SortOption.lowest,
  lagsta: SortOption.lowest,
  niedrigster: SortOption.lowest,
}

// Following values are based on screen breakpoints
export enum ScreenSize {
  xxl = 1400,
  xl = 1200,
  // TODO: Remove when proper tablet screensize breakpoint designs are implemented
  TABLET_HEADER_BREAKPOINT = 1215,
  lg = 992,
  MOBILE_BLOCKER_BREAKPOINT = 900,
  md = 768,
  MOBILE_HEADER_COUNTRY_PICKER_BREAKPOINT = 650,
  sm = 576,
}

/* This is for when sending the recommendation request you need
to specify from where it is triggered
detail-page-view -> Single Product page
home-page-view -> Landing Page (this is also the default value)
*/
export enum RecommendationPlacement {
  detailPage = 'detail-page-view',
  homePage = 'home-page-view',
}
export const DEFAULT_RECOMMENDED_PRODUCT_TO_FETCH = 10
export const DEFAULT_VISITOR_ID = '1717646662.92132547'

export const phoneNumberRegex = /^(\+\d{2,3}[\s]?|0)\d{1,2}[\s]?\d{3,4}[\s]?\d{3,4}$/

export const TOTAL_SKUS_VALUE_PROPOSITION = '150,000'

export const SANCTUM_CSRF_ENDPOINT = '/sanctum/csrf-cookie'

export const XSRF_COOKIE_NAME = 'XSRF-TOKEN'

export const SITEMAP_PRODUCTS_PER_REQUEST = 250

export const PRIMARY_BRAND_COLOR = '#B1F946'
export const PRIMARY_DARKER = '#78C406'
export const DIM_MID_COLOR = '#054C2E'
export const BLACK_COLOR = '#0F2137'
export const SITEMAP_PRODUCTS_PER_PAGE = 1000
export const DARK_GREY_COLOR = '#575757'
export const DIM_MID_LIGHT_COLOR = '#DEEDE2'
export const NEUTRAL_GRAY = '#F7F7F2'
export const DIM_DARKER_COLOR = '#023512'

export const ProfileRoutes: Record<string, { url: string, index: string }> = {
  '/my-account': { url: '/my-account', index: '1' },
  '/my-products': { url: '/my-products', index: '2' },
  '/my-imported-products': { url: '/my-imported-products', index: '8' },
  '/my-catalogs': { url: '/my-catalogs', index: '3' },
  '/my-catalogs/[name]': { url: '/my-catalogs', index: '3' },
  '/my-ordered-products': { url: '/my-ordered-products', index: '4' },
  '/my-orders': { url: '/my-orders', index: '5' },
  '/my-orders/[id]': { url: '/my-orders', index: '5' },
}

export const SocialProofData = [
  {
    logo: 'torney-logo.png',
    logoHeight: 60,
    logoWidth: 166,
    comment: 'Just tell them what you want, in what quantities, and be pleasantly surprised',
    from: 'Maik Jantschik, Head of Production',
  },
  {
    logo: 'pps-logo.png',
    logoHeight: 63,
    logoWidth: 121,
    comment: 'If we say we want something, Droppe makes it happen',
    from: 'Denis Mollov, Co-founder at PPS Manufacturing',
  },
  {
    logo: 'elaproof-logo.png',
    logoHeight: 55,
    logoWidth: 110,
    comment: 'When you want to save time and money, choose Droppe',
    from: 'Mikko Araviita, Deputy Managing Director',
  },
  {
    logo: 'rehu-logo.png',
    logoHeight: 35,
    logoWidth: 163,
    comment: 'I can recommend Droppe, the cooperation has been great',
    from: 'Jussi Kivistö, Store Manager',
  },
  {
    logo: 'nitras-logo.png',
    logoHeight: 50,
    logoWidth: 150,
    comment: 'Droppe acts as a bridge, connecting us with our end users, and we get a lot of quote requests from different markets',
    from: 'Martin Hansen, Regional Manager',
  },
]

// List of all browsers with key-value pair and the value will be
// the maximum version where browser is supported
export const OldestSupportedBrowsers: Record<string, number> = {
  chrome: 18,
  edge: 12,
  safari: 6,
  firefox: 14,
  opera: 15,
  ie: 11,
  android: 118,
  ios: 6.1,
  samsung: 4,
}

export const UPDATE_BROWSER_LINK = 'https://updatemybrowser.org/'

// In order to avoid some precision bugs, We want to save values with 4 decimals
// precision, but to present them in the various UI elements with a 2 decimal precision
export const SAVE_DECIMALS = 4
export const INPUT_DECIMALS = 2

// Common constants for input props
export const MAX_PERCENTAGE = 99
export const MIN_PERCENTAGE = 0
export const STEP = 0.01

export const customPriceDiscountValue = {
  [PriceTag.Discount1]: '0',
  [PriceTag.Discount2]: '0',
  [PriceTag.Discount3]: '0',
  [PriceTag.Discount4]: '0',
  [PriceTag.Discount5]: '0',
}
export const customPriceDiscountSize = {
  [PriceTag.Discount1]: 0,
  [PriceTag.Discount2]: 0,
  [PriceTag.Discount3]: 0,
  [PriceTag.Discount4]: 0,
  [PriceTag.Discount5]: 0,
}

export const ShortcutUseCaseData: { [key: string]: string[] } = {
  'c_work-gloves': ['1457', '1459', '1460', '1462', '1469', '1461', '1478'],
  'c_single-use-gloves': ['1457', '1462', '1472', '1477'],
  c_shoes: ['1462', '1469', '1471', '1479'],
  c_overalls: ['1457', '1462', '1461', '1469'],
  'c_jackets-vests': ['1463', '1469', '1478', '1461'],
  c_pants: ['1463', '1469', '1478', '1461'],
}

export const ShortcutUseCaseLablesWithIds: { [key: string]: string } = {
  1457: 'Chemical resistance',
  1459: 'Cold protective',
  1460: 'Cut resistant',
  1462: 'Food service',
  1469: 'Water resistance',
  1461: 'Heat & flame resistance',
  1478: 'Machine washable',
  1472: 'Medical protection',
  1477: 'Antimicrobial protection',
  1471: 'Slip resistant',
  1463: 'High visibility',
  1479: 'Shoe protection',
}

export type FeatureFlagNames =
  | 'enable-super-user-checkout'
  | 'product-catalog-shortcut-use-case-filter-tag-header'
  | 'empty-state-track-order'
  | 'discount-tag'
  | 'product-card-heart-icon'
  | 'product-catalog-shortcut-use-case-filter-banner-card'
  | 'new-landing-page-hero-section'
  | 'document-request-modal-breadcrumb'
  | 'document-request-modal-texts'
  | 'quote-request-modal-breadcrumb'
  | 'quote-request-modal-texts'
  | 'sample-request-modal-breadcrumb'
  | 'sample-request-modal-texts'
  | 'spp-move-to-checkout-button-text'
  | 'spp-bulk-discount-carousel'
  | 'spp-product-price-with-vat'
  | 'cart-recommended-products-carousel'

export type AppFeatureFlags = Record<FeatureFlagNames, any>

export const CHECKOUT_STEPS = ['shipping', 'payment', 'confirm', 'summary'] as const
// dynamically convert tuple to a union type
export type CheckoutStep = typeof CHECKOUT_STEPS[number]

export const SUPPLIER_DESCRIPTION_MAX_LENGTH = 1200

export const skuCodeRegexFilter: RegExp = /^((?!--)[a-zA-Z0-9-])*$/

export const DEFAULT_MARGIN = 20

export type AuthEventNames =
  | 'LOGGED_OUT'
  | 'LOGGED_IN'

export const DEFAULT_PRODUCT_POSITION = 50
export const RFQ_PRODUCT_POSITION = 100

export const CONSUMPTION_TAKE_RATE_TIERS = {
  0: 0.25,
  500: 0.20,
  2000: 0.15,
  10000: 0.12,
  50000: 0.08,
} as const

export const USER_TIER_DATA = {
  ORG_TOTAL_SPEND: 'organisationTotalSpend',
  CONSUMPTION_TIER_SEGMENT: 'consumptionTierSegment',
  CONSUMPTION_MARGIN_PERCENTAGE: 'consumptionMarginPercentage',
} as const

export const USER_TIER_DATA_LOCAL_STORAGE_KEY = 'user_tier_data'
export const CONSUMPTION_TAKE_RATE_TIER_SEGMENTS = [
  0,
  500,
  1000,
  2500,
  5000,
  7500,
  10000,
  15000,
  20000,
  25000,
  30000,
  40000,
  50000,
  75000,
  100000,
  200000,
  300000,
  400000,
  500000,
  750000,
  1000000,
]

export const IMPORTED_PRODUCTS_TEMPLATE_LINK = 'https://docs.google.com/spreadsheets/d/1IOopoAUaXG2O6cR0qrAndyBsxoS06_rfyklLgidVILk/export/edit?format=xlsx&gid=1071611005'

export const DROPPE_SUPPLIERS_COUNT = 200

export const filterTags = ['top-filter', 'advanced-filter']
