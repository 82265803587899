import { FormInstance } from 'rsuite'

import { ResetPasswordInputs } from '../../../utils/types/auth'

type Error = {
  msg: string
  data: any
}

interface ResetPasswordModel {
  formRef: React.RefObject<FormInstance<ResetPasswordInputs>>
  error: Error
  formValue: ResetPasswordInputs
  handleChange: (formValue: ResetPasswordInputs) => void
  handleResetEmail?: (force?: boolean) => Promise<void>
  openLoginModal: () => void
  resetPassword?: () => void
}

class ResetPassword {
  error: ResetPasswordModel['error']

  formRef: ResetPasswordModel['formRef']

  formValue: ResetPasswordModel['formValue']

  handleChange: ResetPasswordModel['handleChange']

  handleResetEmail?: ResetPasswordModel['handleResetEmail']

  openLoginModal: ResetPasswordModel['openLoginModal']

  resetPassword?: ResetPasswordModel['resetPassword']

  constructor(model: ResetPasswordModel) {
    const {
      error,
      formValue,
      handleChange,
      handleResetEmail,
      openLoginModal,
      formRef,
      resetPassword,
    } = model

    this.error = error
    this.formRef = formRef
    this.formValue = formValue
    this.handleChange = handleChange
    this.handleResetEmail = handleResetEmail
    this.openLoginModal = openLoginModal
    this.resetPassword = resetPassword
  }
}

export default ResetPassword
