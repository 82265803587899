import { FC, RefObject, useEffect, useRef, useState } from 'react'
import Link from 'next/link'
import { Badge, Popover, Whisper } from 'rsuite'
import { OverlayTriggerInstance } from 'rsuite/esm/Picker'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import dynamic from 'next/dynamic'

import HeaderCartPopup from '../../Popups/Cart/HeaderCartPopup'
import useUrls from '../../../services/useUrls'
import CartIcon from '../../Icons/CartIcon'
import { useCart } from '../../Cart/CartProvider'
import { selectCartProductsUnique, selectTotalCartQuantity } from '../../Cart/selectors'
import { ALERT_DURATION, ScreenSize } from '../../../utils/constants'
import useIsOnMobile from '../../../services/useIsOnMobile'

import cartPopupStyles from '../../../styles/CartPopup.module.less'
import styles from '../../../styles/CartIcon.module.less'

const BaseModal = dynamic(() => import('../../Modals/BaseModal'))

interface CartNavProps {
  headerRef?: RefObject<HTMLDivElement>
  className?: string
}

const CartNav: FC<CartNavProps> = ({ headerRef, className }) => {
  const [showMobilePopover, setShowMobilePopover] = useState(false)

  const whisperRef = useRef<OverlayTriggerInstance>(null)
  const { urlT } = useUrls()
  const { asPath, query } = useRouter()
  const { cartState: { cart, isLoading } } = useCart()
  const { t } = useTranslation('common')
  const isOnMobile = useIsOnMobile()
  const isOnTablet = useIsOnMobile(ScreenSize.md)

  const isOnCartPage = asPath === urlT('/cart')
  const isOnCheckoutSummaryPage = asPath.includes(t('summary'))

  const { shareParams } = query
  const products = cart ? selectCartProductsUnique(cart) : []
  const productCount = products.length
  const isPopupOpen = useRef(false)

  const quantityCount = cart ? selectTotalCartQuantity(cart) : 0

  // Value -1 means data is not loaded yet
  const currentQuantityCount = useRef(-1)

  useEffect(() => {
    // Don't do anything if data is not loaded
    if (!cart?.included) {
      return () => {}
    }

    // Don't do anything on initial load and set current value when cart is loaded
    if (currentQuantityCount.current === -1 && !isLoading) {
      currentQuantityCount.current = quantityCount
      return () => {}
    }

    const hasTotalQuantityCountChanged = currentQuantityCount.current !== quantityCount

    if (whisperRef.current
      && !isOnCartPage
      && !shareParams
      && !isOnCheckoutSummaryPage
      && quantityCount
      && hasTotalQuantityCountChanged
    ) {
      currentQuantityCount.current = quantityCount
      if (!isOnMobile) {
        whisperRef.current?.open()
      }
      setShowMobilePopover(true)
      isPopupOpen.current = true
    }

    const timeoutId = setTimeout(() => {
      if (isPopupOpen.current) {
        whisperRef.current?.close()
        isPopupOpen.current = false
      }
    }, ALERT_DURATION)

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [products, isOnCartPage, quantityCount])

  return (
    <div
      data-testid="cart-icon"
      className={`${styles['cart-icon-container']} ${className}`}
    >
      <Link href={urlT('/cart')}>
        <Whisper
          ref={whisperRef}
          container={headerRef?.current ? headerRef.current : undefined}
          placement="bottomEnd"
          trigger={productCount > 0 && !isOnTablet ? 'hover' : 'none'}
          speaker={(
            <Popover
              className={`${cartPopupStyles['cart-popup']} ${cartPopupStyles['header-cart-popup']}`}
              arrow={false}
            >
              <HeaderCartPopup
                cart={cart}
                totalProductsInCart={productCount}
                products={products}
              />
            </Popover>
            )}
          enterable
        >
          <div>
            <Badge
              data-testid="cart-icon-badge"
              className={`${styles.counter} ${productCount ? styles['hover-style'] : ''}`}
              content={productCount < 1 || shareParams ? false : productCount}
            >
              <CartIcon />
            </Badge>
          </div>
        </Whisper>
      </Link>
      {isOnMobile && showMobilePopover && (
        <BaseModal
          closeModal={() => setShowMobilePopover(false)}
          isOpen
          backdrop="static"
          size="sm"
          modalClassName={styles['mobile-cart-popover']}
          backdropClassName={styles['mobile-cart-popover-wrapper']}
          hideButtons
          title={(
            <h4>
              {t('cart:{{count}} products in cart', { count: products.length })}
            </h4>
          )}
        >
          <div
            className={`${cartPopupStyles['cart-popup']} ${cartPopupStyles['header-cart-popup']}`}
          >
            <HeaderCartPopup
              cart={cart}
              totalProductsInCart={productCount}
              products={products}
              displayTitle={false}
              closeModal={() => setShowMobilePopover(false)}
            />
          </div>
        </BaseModal>
      )}
    </div>
  )
}

export default CartNav
