import { NextRouter } from 'next/router'

class Router {
  router: NextRouter | null

  constructor(router: NextRouter | null) {
    this.router = router
  }
}

export default Router
