import { FormInstance } from 'rsuite'

import { Country, CountryId } from '../../../utils/constants'
import { ApplyFormData, SupplierShippingData, UseSupplierProfileDataReturn } from '../../../utils/types/Profile'

type ShippingDetailsState = {
  shippingData: SupplierShippingData
  setShippingData: (key: 'country' | 'limit' | 'days' | 'mov' | 'fee', value: string) => void
}

interface SupplierModel {
  fiShippingDetails?: ShippingDetailsState
  svShippingDetails?: ShippingDetailsState
  deShippingDetails?: ShippingDetailsState

  applyFormErrors?: Record<string, string | undefined>
  applyFormValues?: ApplyFormData
  handleApplyFormChange?: (values: ApplyFormData) => void
  registerSupplier?: () => void
  applyFormRef?: React.RefObject<FormInstance>

  supplierProfileData?: UseSupplierProfileDataReturn
  profileDetails?: {
    formValues: Record<'company' | 'firstname' | 'lastname', string>
    setFormValues: (values: Record<'company' | 'firstname' | 'lastname', string>) => void
    updateProfileDetails: () => Promise<void>
  }
  supplierText?: {
    onChange: (value: string) => void
  }
}

class Supplier implements SupplierModel {
  fiShippingDetails: SupplierModel['fiShippingDetails']

  svShippingDetails: SupplierModel['svShippingDetails']

  deShippingDetails: SupplierModel['deShippingDetails']

  applyFormErrors: SupplierModel['applyFormErrors']

  applyFormValues: SupplierModel['applyFormValues']

  handleApplyFormChange: SupplierModel['handleApplyFormChange']

  registerSupplier: SupplierModel['registerSupplier']

  applyFormRef: SupplierModel['applyFormRef']

  supplierProfileData: SupplierModel['supplierProfileData']

  profileDetails: SupplierModel['profileDetails']

  supplierText?: SupplierModel['supplierText']

  constructor(model: SupplierModel) {
    this.fiShippingDetails = model.fiShippingDetails
    this.svShippingDetails = model.svShippingDetails
    this.deShippingDetails = model.deShippingDetails

    this.applyFormErrors = model.applyFormErrors
    this.applyFormValues = model.applyFormValues
    this.handleApplyFormChange = model.handleApplyFormChange
    this.registerSupplier = model.registerSupplier
    this.applyFormRef = model.applyFormRef

    this.supplierProfileData = model.supplierProfileData
    this.profileDetails = model.profileDetails
    this.supplierText = model.supplierText
  }

  getCountry(countryId: CountryId) {
    return Country[countryId]
  }
}

export default Supplier
